import { Atom } from ":mods";
import { createSignal, splitProps } from "solid-js";
import {  SubmissionsDetailsProps } from "../../../../models";
import { FoundationSignup } from "./foundation-signup";
import { Nationality } from "./nationality";
import { Language } from "./language";
import { MoreInformation } from "./more-information";
export type PersonalViewProps= {
   personalData:SubmissionsDetailsProps
}
export function PersonalSection(props:PersonalViewProps) {
  const [local, others] = splitProps(props, ["personalData"]);

  return (
    <>
      <p class="text-14px font-700 leading-21px mb-20px">Personal Information</p>
      <MoreInformation data={local.personalData.personal} />
      <FoundationSignup data={local.personalData.foundation_sign_up}  />
      <Nationality data={local.personalData.nationality} />
      <Language data={local.personalData.languages_foundation_sign_up} />
    </>
  );
}
